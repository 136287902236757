html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  -webkit-hyphens: none;
  hyphens: none;
  tab-size: 4;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  background: none;
  line-height: 1.5;
}

pre[class*="language-"]::selection, code[class*="language-"]::selection {
  background: #b3d4fc;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre.language-compiler-error {
  background-color: #fff5f5;
  border: 2px solid red;
}

code[class*="language-"] mark {
  color: inherit;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-100.94fe268d.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-100.4e89e49d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-100italic.299c046b.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-100italic.ee255ad1.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-200.96ca0327.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-200.650b1a54.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-200italic.ccc2d9e4.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-200italic.2ef982e4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-300italic.e5a09d0d.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-300italic.9b8a169f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-300.6a681b34.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-300.af0b2c0d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-regular.5f1759cf.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-regular.b52515eb.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-500.bf34995a.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-500.6b91ee66.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-italic.cbbf0fc4.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-italic.4c0aadd3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-500italic.fa638408.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-500italic.5b6d68ac.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-600.ce4978f4.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-600.4ab4d0d6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-600italic.ff35b96f.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-600italic.ff9daf95.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-700.da338742.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-700.a590b3f8.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("ibm-plex-serif-v14-latin-ext_latin-700italic.6cce2c2c.woff2") format("woff2"), url("ibm-plex-serif-v14-latin-ext_latin-700italic.b109de9b.woff") format("woff");
}

@font-face {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("noto-sans-v25-latin-ext_latin-regular.5725be15.woff2") format("woff2"), url("noto-sans-v25-latin-ext_latin-regular.3b9f2410.woff") format("woff");
}

@font-face {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("noto-sans-v25-latin-ext_latin-700.51b192f7.woff2") format("woff2"), url("noto-sans-v25-latin-ext_latin-700.debf555b.woff") format("woff");
}

@font-face {
  font-family: Noto Sans;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("noto-sans-v25-latin-ext_latin-italic.dc4771fe.woff2") format("woff2"), url("noto-sans-v25-latin-ext_latin-italic.68be5892.woff") format("woff");
}

@font-face {
  font-family: Noto Sans;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("noto-sans-v25-latin-ext_latin-700italic.54d8fd33.woff2") format("woff2"), url("noto-sans-v25-latin-ext_latin-700italic.527ea310.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-200.8ab4ecce.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-200.d58b2e62.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-300.44128e78.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-300.fee6adbd.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-regular.960cb98a.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-regular.863cb42f.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-500.bac81cb6.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-500.cdacbba2.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-600.13aa8f3e.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-600.79df4727.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-700.b0689c2b.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-700.26d8fb21.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-800.a5067a8a.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-800.ef161fac.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 200;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-200italic.8fcde80d.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-200italic.107241e0.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-900.83f263e1.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-900.1e24cb82.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-300italic.9ebc93da.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-300italic.cc7658da.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-italic.34332145.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-italic.558d488f.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-600italic.0a5ba319.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-600italic.3355f463.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 500;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-500italic.ff8872d8.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-500italic.17b04aca.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-700italic.bd0cf2b0.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-700italic.0063fb24.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 800;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-800italic.dcc0882e.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-800italic.5b48310c.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  font-style: italic;
  font-weight: 900;
  src: local(""), url("source-code-pro-v20-latin-ext_latin-900italic.5036026f.woff2") format("woff2"), url("source-code-pro-v20-latin-ext_latin-900italic.18d49674.woff") format("woff");
}



* {
  box-sizing: border-box;
}

body {
  letter-spacing: .01rem;
  font-family: IBM Plex Serif, serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Noto Sans, sans-serif;
}

code {
  font-family: Source Code Pro, monospace;
  font-weight: 600;
}

.K4NMUa_dialog::backdrop {
  background: #00000080;
}

.K4NMUa_container {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.K4NMUa_title {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}

.K4NMUa_close {
  aspect-ratio: 1 / 1;
  border-width: 0 0 1px 1px;
  position: absolute;
  top: 0;
  right: 0;
}

.K4NMUa_sets {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.K4NMUa_set {
  justify-content: space-between;
  align-items: center;
  gap: 5em;
  display: flex;
}

.K4NMUa_keycaps {
  gap: .25em;
  display: flex;
}

.K4NMUa_keycap {
  white-space: nowrap;
  border: 1.5px solid #000;
  padding: 3px 5px;
  line-height: .85em;
  display: inline-block;
  box-shadow: 1.5px 1.5px #00000040;
}

.W4YxZW_chunkyImage {
}

.ljQcMW_fullImage {
}

.D9r7qa_container {
  background: #eee8aa;
  margin: 0;
  padding: 1em;
}

.D9r7qa_quote {
  margin: 0;
  font-size: 2.5rem;
}

.D9r7qa_caption {
  text-align: right;
  font-style: italic;
}

.D9r7qa_caption:before {
  content: "— ";
}

.vmDZ2q_chunkyImage {
}

.YASdAa_fullImage {
}

.tolyLa_hovered {
  position: relative;
}

.tolyLa_copy {
  display: none;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.tolyLa_hovered:hover .tolyLa_copy {
  display: block;
}

.hQx4Ja_outer {
}

.hQx4Ja_container {
  text-align: center;
  background-color: #fff;
  place-content: center;
  font-size: 3rem;
  display: grid;
}

.ySmK_a_container {
  object-fit: contain;
}

.aBfWRG_outer {
}

.aBfWRG_container {
  background-color: #fff;
  padding: 2rem 4rem;
  font-size: 1.5rem;
  position: relative;
}

.aBfWRG_container code[class*="language-"], .aBfWRG_container pre[class*="language-"] {
  font-size: 1.15rem;
  line-height: 1.2;
}

.aBfWRG_container h1 {
  margin: 0 0 2rem;
}

.aBfWRG_container h1 code {
  margin: 0;
  line-height: 1;
}

.aBfWRG_container ul, .aBfWRG_container ol {
  font-size: 1.75rem;
}

.aBfWRG_container li {
  margin: 1.5rem 0;
}

.aBfWRG_pageNumber {
  color: #a0a0a0;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

@media print {
  .aBfWRG_pageNumber {
    display: none;
  }
}

.IZYkyq_outer {
}

.IZYkyq_-inner {
  width: var(--slide-target-width);
  height: var(--slide-target-height);
  transform-origin: 0 0;
}

.IZYkyq_inner4x3 {
  --slide-target-width: 1600px;
  --slide-target-height: 1200px;
}

.IZYkyq_inner16x9 {
  --slide-target-width: 1600px;
  --slide-target-height: 900px;
}

@media print {
  .IZYkyq_outer:has(.IZYkyq_inner16x9) {
    width: 800px;
    height: 450px;
  }

  .IZYkyq_outer:has(.IZYkyq_inner4x3) {
    width: 800px;
    height: 600px;
  }

  .IZYkyq_-inner {
    scale: .5;
  }
}

.cCiXQq_container {
  background-color: #646464;
}

@media screen {
  .cCiXQq_container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.pj8KFq_container {
  break-after: page;
  break-inside: avoid-page;
  background-color: #fff;
}

.pj8KFq_notes {
  border-top: 1px solid #000;
}

._7OtJqa_container {
}

._7OtJqa_hidden {
  display: none;
}

.e4Rufq_-center {
  grid-template-rows: minmax(0, auto);
  grid-template-columns: minmax(0, auto);
  place-items: center;
  display: grid;
}

.e4Rufq_-expand {
  width: 100%;
  height: 100%;
}

.e4Rufq_-fullImage {
  max-width: calc(var(--slide-target-width) * .9);
  max-height: calc(var(--slide-target-height) * .9);
  object-fit: contain;
  padding: 0 2em;
}

.e4Rufq_-chunkyImage {
  margin: 0 auto;
  display: block;
}

.gj9EtG_-resizingSlideContainer {
  max-width: 100%;
  max-height: 100%;
}

.gj9EtG_-meta {
  background-color: ivory;
}

.gj9EtG_-label {
  margin: 0;
  padding: .25em;
}

.gj9EtG_container {
  grid-template-rows: minmax(25%, 60%) minmax(min-content, min-content) minmax(25%, 1fr) minmax(min-content, min-content);
  grid-template-columns: repeat(4, minmax(0, 25%));
  grid-template-areas: "gj9EtG_curr gj9EtG_curr gj9EtG_curr gj9EtG_curr"
                       "gj9EtG_lbll gj9EtG_lbll gj9EtG_lblr gj9EtG_lblr"
                       "gj9EtG_note gj9EtG_note gj9EtG_next gj9EtG_next"
                       "gj9EtG_note gj9EtG_note gj9EtG_time gj9EtG_time";
  display: grid;
}

.gj9EtG_slideCurrent {
  grid-area: gj9EtG_curr;
}

.gj9EtG_labelLeft {
  grid-area: gj9EtG_lbll;
}

.gj9EtG_labelRight {
  text-align: right;
  grid-area: gj9EtG_lblr;
}

.gj9EtG_notes {
  grid-area: gj9EtG_note;
}

.gj9EtG_slideNext {
  grid-area: gj9EtG_next;
}

.gj9EtG_timer {
  text-align: center;
  grid-area: gj9EtG_time;
  padding: .25em;
}

/*# sourceMappingURL=index.0f3c3d32.css.map */
